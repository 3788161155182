import React from "react"

import closeIcon from "../../../assets/close.svg"

import { Container, CloseContainer, Item } from "./styles"

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const MenuIcon = ({ open }) => {
  if (!open)
    return (
      <Container variants={container} initial="hidden" animate="visible">
        {[0, 1, 2, 3].map(index => (
          <Item key={index} variants={item} />
        ))}
      </Container>
    )
  return (
    <CloseContainer
      initial={{ scale: 0 }}
      animate={{ rotate: 180, scale: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
    >
      <img src={closeIcon} alt="close" />
    </CloseContainer>
  )
}

export default MenuIcon
