import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { ThemeProvider } from "styled-components"

import { GlobalStyle, theme } from "../../utils/styles"
import ContextProvider from "../provider/ContextProvider"

import Header from "./Header"
import Footer from "./Footer"
import CookieBanner from "./CookieBanner"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteLayoutQuery {
      allMarkdownRemark {
        nodes {
          frontmatter {
            id
            lang
            type
            date(formatString: "MMMM DD, YYYY")
            title
            slug
            startYear
            endYear
          }
        }
      }
      site {
        siteMetadata {
          languages {
            sv
            en
          }
        }
      }
    }
  `)

  // Locale
  const { locale } = useIntl()
  const { languages } = data.site.siteMetadata

  // State
  const [checkRedirect, setCheckRedirect] = useState(false)

  // Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
    if (process.env.GATSBY_COMING_SOON === "active") {
      navigate("/launching-soon")
    } else {
      setCheckRedirect(true)
    }
  }, [])

  if (!checkRedirect) return null

  return (
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header
          conflicts={data.allMarkdownRemark.nodes.filter(
            ({ frontmatter: { type, lang }}) => {
              return type === "conflict" && lang === languages[locale]
            }
          ).sort((a, b) => {
            return a.frontmatter.startYear - b.frontmatter.startYear;
          })}
        />
        <main>{children}</main>
        <Footer />
        <CookieBanner />
      </ThemeProvider>
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
