import styled from "styled-components"
import { Link } from "gatsby"
import { breakpoints } from "../../../utils/styles"

export const SectionGap = styled.div`
  width: 100%;
  height: 80px;

  @media (max-width: ${breakpoints.xl}px) {
    height: 64px;
  }
`

export const Section = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1200;
  background-color: ${({ theme }) => theme.color.white};
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 8px 16px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 8px;
  }
`

export const Column = styled.div`
  min-width: 20%;
  display: flex;
  justify-content: ${({ right }) => (right ? "flex-end" : "flex-start")};
`

export const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;

  @media (max-width: ${breakpoints.s}px) {
    width: 32px;
    height: 32px;
    overflow: hidden;
  }
`

export const Text = styled.p`
  margin: 0;
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`

export const LogoLink = styled(Link)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

export const Logo = styled.img`
  height: 64px;
  margin: 0 12px 0 0;

  @media (max-width: ${breakpoints.xl}px) {
    height: 40px;
    margin: 0 8px 0 0;
  }
`

export const LogoText = styled.h2`
  color: ${({ theme }) => theme.color.black};
  margin: 0 8px 0 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
  }
`

export const Flag = styled.img`
  margin: 0 0 0 12px;

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0;
  }
`

export const DrawerContent = styled.div`
  color: ${({ theme }) => theme.color.white};
  padding: 32px;

  @media (max-width: ${breakpoints.s}px) {
    padding: 16px 16px 124px;
  }
`

export const ConflictGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.s}px) {
    gap: 16px;
  }
`

export const ConflictCard = styled(Link)`
  background: ${({ theme }) => theme.color.white};
  border-radius: 16px;
  overflow: hidden;
  color: ${({ theme }) => theme.color.black};
  box-shadow: ${({ theme }) => theme.shadow.card};
  text-decoration: none;
`

export const ConflictData = styled.div`
  padding: 16px;

  h3 {
    color: ${({ theme }) => theme.color.primary};
    text-align: center;
    margin-bottom: 12px;

    @media (max-width: ${breakpoints.s}px) {
      font-size: 18px;
    }
  }

  p {
    text-align: center;
    margin-bottom: 8px;

    @media (max-width: ${breakpoints.s}px) {
      font-size: 14px;
    }
  }
`

export const ConflictImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin: 0;
  line-height: none;

  @media (max-width: ${breakpoints.s}px) {
    height: 140px;
  }
`

export const LinkGrid = styled.div`
  display: flex;
  flex-direction: column;
`

export const LinkItem = styled.a`
  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  padding: 8px 0;
  text-decoration: none;
`

export const LinkIcon = styled.img`
  margin: 0 8px 0 0;
`
