import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints } from "../../../utils/styles"

export const DrawerBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #000000aa;
  cursor: pointer;
`

export const DrawerContent = styled(motion.div)`
  position: fixed;
  z-index: 3;
  height: calc(100vh - 80px);
  padding-top: 80px;
  width: 768px;
  background: ${({ theme }) => theme.gradient.blue};
  box-shadow: ${({ theme }) => theme.shadow.card};
  top: 0;
  left: 0;
  overflow-y: auto;

  @media (max-width: ${breakpoints.l}px) {
    height: calc(100vh - 68px);
    padding-top: 68px;
  }

  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }

  @media (max-width: ${breakpoints.s}px) {
    height: calc(100vh - 64px);
    padding-top: 64px;
  }
`
