import React, { Fragment } from "react"
import { DrawerBackground, DrawerContent } from "./styles"

const defaultBackgroundVariants = {
  open: {
    opacity: 1,
    display: "block",
  },
  closed: {
    opacity: 0,
    display: "none",
    transition: {
      zIndex: {
        delay: 0.5,
      },
      display: {
        delay: 0.5,
      },
    },
  },
}

const defaultDrawerVariants = {
  open: {
    left: 0,
  },
  closed: {
    left: "-768px",
  },
}

const Drawer = ({ children, isOpen, close }) => {
  return (
    <Fragment>
      <DrawerBackground
        initial={false}
        transition={{ type: "tween" }}
        variants={defaultBackgroundVariants}
        animate={isOpen ? "open" : "closed"}
        onClick={close}
      />
      <DrawerContent
        transition={{ type: "tween" }}
        initial={false}
        variants={defaultDrawerVariants}
        animate={isOpen ? "open" : "closed"}
      >
        {children}
      </DrawerContent>
    </Fragment>
  )
}

export default Drawer
