import { useState, useEffect, useRef } from "react"

export const useDetectOutsideClick = initialState => {
  const ref = useRef()
  const [isActive, setIsActive] = useState(initialState)

  useEffect(() => {
    const clickEvent = event => {
      if (ref.current !== null && !ref.current.contains(event.target)) {
        setIsActive(!isActive)
      }
    }

    if (isActive) {
      window.addEventListener("click", clickEvent)
    }

    return () => {
      window.removeEventListener("click", clickEvent)
    }
  }, [isActive, ref])

  return { ref, isActive, setIsActive }
}
