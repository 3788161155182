import React from "react"
import PropTypes from "prop-types"

import { Button } from "./styles"

const RaisedButton = ({ children, onClick, type }) => {
  return (
    <Button type={type || "button"} onClick={onClick}>
      {children}
    </Button>
  )
}

RaisedButton.propTypes = {
  children: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
}

export default RaisedButton
