import styled from "styled-components"
import { breakpoints } from "../../../utils/styles"

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  button {
  }
`

export const Menu = styled.nav`
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 64px;
  right: 0;
  min-width: 180px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: ${({ isActive }) => (isActive ? "1" : "0")};
  visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
  transform: ${({ isActive }) =>
    isActive ? "translateY(0)" : "translateY(-20px)"};
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  @media (max-width: ${breakpoints.s}px) {
    top: 54px;
  }
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const ListItem = styled.li`
  border-bottom: ${({ noBorder }) => (noBorder ? "none" : "1px solid #dddddd")};

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: none;
    cursor: pointer;
  }
`

export const Flag = styled.img`
  margin: 0 0 0 12px;
`
