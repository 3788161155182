import React, { useContext } from "react"
import CookieConsent from "react-cookie-consent";
import { ThemeContext } from 'styled-components';
import { useIntl } from "gatsby-plugin-intl"

const CookieBanner = () => {
  const intl = useIntl()
  const { color } = useContext(ThemeContext);

  return (
    <CookieConsent
      location="bottom"
      buttonText={intl.formatMessage({ id: "consent.button_text" })}
      cookieName="cookieConsent"
      style={{
        background: color.black,
        display: 'flex',
        alignItems: 'center'
      }}
      buttonStyle={{
        color: color.white,
        backgroundColor: color.primary,
        fontWeight: 'bold',
        borderRadius: '24px',
        padding: '10px 16px',
      }}
      expires={150}
    >
      {intl.formatMessage({ id: "consent.description" })}
    </CookieConsent>
  )
}

export default CookieBanner
