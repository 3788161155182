import React from "react"
import { useDetectOutsideClick } from "../../hooks/UseDetectOutsideClick"
import { MenuContainer, Menu, Flag, List, ListItem } from "./styles"

const DropdownMenu = ({ children, menu }) => {
  // Hooks
  const { ref, isActive, setIsActive } = useDetectOutsideClick(false)

  // On click
  const onClickMenu = () => setIsActive(!isActive)

  return (
    <MenuContainer>
      <div
        tabIndex={0}
        role="button"
        onKeyDown={event => {
          if (event.code === "Enter") {
            onClickMenu()
          }
        }}
        onClick={onClickMenu}
      >
        {children}
      </div>
      <Menu ref={ref} isActive={isActive}>
        <List>
          {menu.map(({ id, text, icon, onClick }, i) => (
            <ListItem key={id} noBorder={menu.length === i + 1}>
              <div
                tabIndex={0}
                role="button"
                onKeyDown={event => {
                  if (event.code === "Enter") {
                    onClick()
                    onClickMenu()
                  }
                }}
                onClick={() => {
                  onClick()
                  onClickMenu()
                }}
              >
                {text}
                <Flag src={icon} alt={text} />
              </div>
            </ListItem>
          ))}
        </List>
      </Menu>
    </MenuContainer>
  )
}

export default DropdownMenu
