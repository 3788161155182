import React, { Fragment, useState, useEffect } from "react"
import { changeLocale, useIntl } from "gatsby-plugin-intl"

import {
  Section,
  SectionGap,
  Container,
  Column,
  HeaderButton,
  LogoLink,
  LogoText,
  Logo,
  Text,
  Flag,
  DrawerContent,
  ConflictGrid,
  ConflictCard,
  ConflictData,
  ConflictImage,
  LinkGrid,
  LinkItem,
  LinkIcon,
} from "./styles"

import baskerIcon from "../../../assets/basker.svg"
import englishIcon from "../../../assets/english.svg"
import swedishIcon from "../../../assets/swedish.svg"
import arrowIcon from "../../../assets/arrow-forward.svg"

import bosniaImage from "../../../assets/conflicts/bosnia_image_1.jpg"
import maliImage from "../../../assets/conflicts/mali_image_1.jpg"
import suezImage from "../../../assets/conflicts/suez_image_1.jpg"
import kongoImage from "../../../assets/conflicts/kongo_image_1.jpg"

import MenuIcon from "../../UI/MenuIcon"
import DropdownMenu from "../../UI/DropdownMenu"
import Drawer from "../../UI/Drawer"
import Heading, { Type } from "../../UI/Heading"

const Header = ({ conflicts }) => {
  // Hooks
  const intl = useIntl()

  // State
  const [open, setOpen] = useState(false)

  // Freeze content when menu is open
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : ""
  }, [open])

  // Actions
  const onMenuClick = () => setOpen(!open)
  const onCloseMenu = () => setOpen(false)

  // Render flag
  const renderCurrentFlag = (locale, list) => {
    const found = list.find(item => item.locale === locale)
    return <Flag src={found.icon} alt={found.text} />
  }

  const menuImages = {
    kongo: kongoImage,
    mali: maliImage,
    bosnia: bosniaImage,
    suez: suezImage,
  }

  // Languages
  const langList = [
    {
      id: 1,
      locale: "sv",
      text: intl.formatMessage({ id: "header.languages.sv" }),
      icon: swedishIcon,
      onClick: () => changeLocale("sv"),
    },
    {
      id: 2,
      locale: "en",
      text: intl.formatMessage({ id: "header.languages.en" }),
      icon: englishIcon,
      onClick: () => changeLocale("en"),
    },
  ]

  // Links
  const linkList = [
    {
      id: 1,
      text: intl.formatMessage({ id: "header.menu_link_1" }),
      link: "https://www.baskerbla.se/teachers/",
    },
    {
      id: 2,
      text: intl.formatMessage({ id: "header.menu_link_2" }),
      link: "https://www.armemuseum.se/",
    },
    {
      id: 3,
      text: intl.formatMessage({ id: "header.menu_link_3" }),
      link:
        "https://digitaltmuseum.se/021099525828/pa-modigt-allvar-i-armemuseums-samlingar-foremal-fran-fn-missioner",
    },
  ]

  return (
    <Fragment>
      <SectionGap />
      <Section>
        <Container>
          <Column>
            <HeaderButton
              role="button"
              tabIndex={0}
              onClick={onMenuClick}
              onKeyDown={event => {
                if (event.code === "Enter") {
                  onMenuClick()
                }
              }}
            >
              <MenuIcon open={open} />
              <Text>
                {intl.formatMessage({
                  id: open ? "header.menu_text_close" : "header.menu_text",
                })}
              </Text>
            </HeaderButton>
          </Column>
          <LogoLink to="/">
            <Logo
              src={baskerIcon}
              alt={intl.formatMessage({ id: "header.title" })}
            />
            <LogoText>{intl.formatMessage({ id: "header.title" })}</LogoText>
          </LogoLink>
          <Column right>
            <DropdownMenu menu={langList}>
              <HeaderButton>
                <Text>{intl.formatMessage({ id: "header.lang_text" })}</Text>
                {renderCurrentFlag(intl.locale, langList)}
              </HeaderButton>
            </DropdownMenu>
          </Column>
        </Container>
      </Section>
      <Drawer isOpen={open} close={onCloseMenu}>
        <DrawerContent>
          <Heading type={Type.h3}>
            {intl.formatMessage({ id: "header.menu_title_conflicts" })}
          </Heading>
          <ConflictGrid>
            {conflicts.map(
              ({ frontmatter: { id, title, startYear, endYear, slug } }) => (
                <ConflictCard
                  key={id}
                  to={`/${intl.locale}${slug}`}
                  onClick={onCloseMenu}
                >
                  <ConflictImage src={menuImages[id]} alt={title} />
                  <ConflictData>
                    <h3>
                      {startYear} - {endYear}
                    </h3>
                    <p>{title}</p>
                  </ConflictData>
                </ConflictCard>
              )
            )}
          </ConflictGrid>
          <Heading type={Type.h3}>
            {intl.formatMessage({ id: "header.menu_title_links" })}
          </Heading>
          <LinkGrid>
            {linkList.map(({ id, text, link }) => (
              <LinkItem
                key={id}
                href={link}
                onClick={onCloseMenu}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkIcon src={arrowIcon} alt={text} />
                {text}
              </LinkItem>
            ))}
          </LinkGrid>
        </DrawerContent>
      </Drawer>
    </Fragment>
  )
}

export default Header
