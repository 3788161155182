import styled from "styled-components"

export const Button = styled.button`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  min-width: 160px;
  height: 48px;
  padding: 10px 16px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 24px;
  cursor: pointer;
`
