import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints } from "../../../utils/styles"

export const Container = styled(motion.ul)`
  width: 24px;
  height: 24px;
  display: grid;
  overflow: hidden;
  margin: 0 8px 0 0;
  padding: 4px;
  list-style: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2px;

  @media (max-width: ${breakpoints.s}px) {
    margin-right: 0;
  }
`

export const CloseContainer = styled(motion.div)`
  width: 24px;
  height: 24px;
  padding: 4px;
  margin-right: 8px;

  @media (max-width: ${breakpoints.s}px) {
    margin-right: 0;
  }

  img {
    margin: 0;
    padding: 0;
  }
`

export const Item = styled(motion.li)`
  border: 2px solid #000000;
  border-radius: 3px;
`
